<template>
  <div>
    <section>
      <b-form-row v-if="restrictAddLinkAccountToMultipleCity">
        <b-col>
          <b-alert show variant="danger">{{
            DISPLAY_MESSAGES.MULTIPLE_CITIES_ADD_LINK_ACCOUNT_ERROR
          }}</b-alert>
        </b-col>
      </b-form-row>
      <b-form
        id="external-link-account-form"
        name="external-link-account-form"
        v-if="showForm && !restrictAddLinkAccountToMultipleCity"
        @submit.prevent="onSubmitExternalLinkAccount"
        autocomplete="off"
        novalidate
      >
        <b-form-row>
          <b-col cols="12" sm="6" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">Authentication method</h4>
              <div class="floating-select-field">
                <b-form-select
                  id="authentication-method"
                  class="form-control"
                  v-model="authenticationMethod"
                  @input="authenticationMethod = $event"
                  :class="{
                    'is-value-exist': authenticationMethod != null,
                  }"
                >
                  <b-form-select-option value="azureB2C"
                    >Azure B2C</b-form-select-option
                  >
                </b-form-select>
                <label for="authentication-method">Authentication method</label>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" md="5" lg="3">
            <b-form-group>
              <h4 class="page-sub-header">Link account name</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="external-link-account-name"
                  type="text"
                  placeholder=" "
                  v-model="externalLinkAccountDetails.accountName"
                  required
                ></b-form-input>
                <label for="external-link-account-name"
                  >External link account name</label
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !externalLinkAccountDetails.accountName && formSubmitted
                  "
                  >Link account name required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="7" md="5" lg="4" class="pt-md-4 mt-md-3">
            <b-form-group>
              <b-form-checkbox
                id="property-dependent"
                name="property-dependent"
                :disabled="isAddressSetupDone"
                v-model="externalLinkAccountDetails.isForEachProperty"
              >
                Property dependent
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-if="isAddressSetupDone">
          <b-col>
            <h6 class="font-14">
              <InfoCircelYellow class="mr-1 mt-n1" />
              {{ DISPLAY_MESSAGES.ADDRESS_SETUP_NOT_DONE_LINK_ACCOUNT_ERROR }}
            </h6>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Auth code endpoint</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="auth-code-end-point"
                  type="text"
                  placeholder=" "
                  v-model="externalLinkAccountDetails.tenantAuthCodeEndPoint"
                  required
                ></b-form-input>
                <label for="auth-code-end-point">Auth code endpoint</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !externalLinkAccountDetails.tenantAuthCodeEndPoint &&
                    formSubmitted
                  "
                  >Auth code endpoint required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    externalLinkAccountDetails.tenantAuthCodeEndPoint &&
                    formSubmitted &&
                    !validateUrl(
                      externalLinkAccountDetails.tenantAuthCodeEndPoint
                    )
                  "
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Access token endpoint</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="access-token-end-point"
                  type="text"
                  placeholder=" "
                  v-model="externalLinkAccountDetails.tenantAccessTokenEndPoint"
                ></b-form-input>
                <label for="access-token-end-point"
                  >Access token endpoint</label
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !externalLinkAccountDetails.tenantAccessTokenEndPoint &&
                    formSubmitted
                  "
                  >Access token endpoint required.</b-form-invalid-feedback
                >
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    externalLinkAccountDetails.tenantAccessTokenEndPoint &&
                    formSubmitted &&
                    !validateUrl(
                      externalLinkAccountDetails.tenantAccessTokenEndPoint
                    )
                  "
                  >Invalid url.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Client ID</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="client-id"
                  type="text"
                  placeholder=" "
                  v-model="externalLinkAccountDetails.clientId"
                ></b-form-input>
                <label for="client-id">Client ID</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!externalLinkAccountDetails.clientId && formSubmitted"
                  >Client ID required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Client secret</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="client-secret"
                  type="text"
                  placeholder=" "
                  v-model="externalLinkAccountDetails.clientSecret"
                ></b-form-input>
                <label for="client-secret">Client secret</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="
                    !externalLinkAccountDetails.clientSecret && formSubmitted
                  "
                  >Client secret required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Policy</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="policy"
                  type="text"
                  placeholder=" "
                  v-model="externalLinkAccountDetails.policy"
                ></b-form-input>
                <label for="policy">Policy</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!externalLinkAccountDetails.policy && formSubmitted"
                  >Policy required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Scope</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="scope"
                  type="text"
                  placeholder=" "
                  v-model="externalLinkAccountDetails.scope"
                ></b-form-input>
                <label for="scope">Scope</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!externalLinkAccountDetails.scope && formSubmitted"
                  >Scope required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <h4 class="page-sub-header">Grant type</h4>
              <div class="floating-input-field">
                <b-form-input
                  id="grant-type"
                  type="text"
                  placeholder=" "
                  v-model="externalLinkAccountDetails.grantType"
                ></b-form-input>
                <label for="grant-type">Grant type</label>
                <b-form-invalid-feedback
                  class="d-block"
                  v-if="!externalLinkAccountDetails.grantType && formSubmitted"
                  >Grant type required.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="my-4">
          <b-col>
            <b-button
              type="submit"
              v-activeBlur
              variant="primary"
              :disabled="saveLoadingIcon"
              >Save
              <b-spinner
                v-if="saveLoadingIcon"
                label="Spinning"
                small
                class="ml-2"
              ></b-spinner
            ></b-button>
            <b-button
              type="button"
              v-activeBlur
              @click="onResetExternalLinkAccount"
              class="ml-3"
              variant="outline-secondary"
              >Cancel</b-button
            >
          </b-col>
        </b-form-row>
      </b-form>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { DISPLAY_MESSAGES } from '../../utilities/constants'
import { useValidateFields } from '@/composables/useValidateFields'
import { cloneDeep } from 'lodash'
import InfoCircelYellow from '../../assets/svg/info-circle-yellow.svg'

export default {
  name: 'AddEditExternalLinkAccount',
  setup() {
    const { validateUrl, scrollToErrorMessage } = useValidateFields()

    return { validateUrl, scrollToErrorMessage }
  },
  data () {
    return {
      isEditPage: false,
      authenticationMethod: 'azureB2C',
      cloneExternalLinkAccountDetails: null,
      externalLinkAccountDetails: {
        tenantId: null,
        accountName: null,
        tenantAuthCodeEndPoint: null,
        tenantAccessTokenEndPoint: null,
        policy: null,
        clientId: null,
        clientSecret: null,
        scope: null,
        grantType: null,
        isForEachProperty: false
      },
      externalLinkAccountDetailsLoading: false,
      showForm: true,
      formSubmitted: false,
      saveLoadingIcon: false,
      DISPLAY_MESSAGES: DISPLAY_MESSAGES
    }
  },
  components: {
    InfoCircelYellow
  },
  computed: {
    restrictAddLinkAccountToMultipleCity () {
      return (
        (this.selectedTenant.length > 1 ||
          this.selectedTenant.some((tenant) => tenant.tenantID === 0)) &&
        !this.isEditPage
      )
    },
    isAddressSetupDone () {
      return !!(this.selectedTenant.length &&
        !this.selectedTenant[0].isAddressSetupDone)
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant
    })
  },
  created () {
    if (this.$route.name === 'Settings-edit-external-link-account') {
      this.isEditPage = true
      this.getExternalLinkAccountDetails()
    }
  },
  mounted () {
    this.cloneExternalLinkAccountDetails = cloneDeep(
      this.externalLinkAccountDetails
    )
    this.setPropertyDependent()
  },
  methods: {
    showToaster (message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type
      })
    },
    getExternalLinkAccountDetails () {
      this.externalLinkAccountDetailsLoading = true
      const postObj = {
        linkAccountId: this.$route.params.id,
        authenticationType: 'external-link-account'
      }
      this.$store
        .dispatch('settings/getLinkAccountDetails', postObj)
        .then((response) => {
          this.externalLinkAccountDetailsLoading = false
          this.externalLinkAccountDetails = response.data
          this.cloneExternalLinkAccountDetails = cloneDeep(response.data)
        })
        .catch(() => {
          this.externalLinkAccountDetailsLoading = false
          this.$store.commit('common/setCustomToastData', {
            message: false,
            key: 'FAILED',
            type: 'danger'
          })
        })
    },
    onResetExternalLinkAccount () {
      this.externalLinkAccountDetails = cloneDeep(
        this.cloneExternalLinkAccountDetails
      )
      this.showForm = false
      this.$nextTick(() => {
        this.showForm = true
      })
    },
    validateExternalLinkAccountForm () {
      const accountName = !!this.externalLinkAccountDetails.accountName
      const tenantAuthCodeEndPoint = this.externalLinkAccountDetails
        .tenantAuthCodeEndPoint
        ? this.validateUrl(
          this.externalLinkAccountDetails.tenantAuthCodeEndPoint
        )
        : false
      const tenantAccessTokenEndPoint = this.externalLinkAccountDetails
        .tenantAccessTokenEndPoint
        ? this.validateUrl(
          this.externalLinkAccountDetails.tenantAccessTokenEndPoint
        )
        : false
      const policy = !!this.externalLinkAccountDetails.policy
      const clientId = !!this.externalLinkAccountDetails.clientId
      const clientSecret = !!this.externalLinkAccountDetails.clientSecret
      const scope = !!this.externalLinkAccountDetails.scope
      const grantType = !!this.externalLinkAccountDetails.grantType
      this.scrollToErrorMessage()
      return (
        accountName &&
        tenantAuthCodeEndPoint &&
        tenantAccessTokenEndPoint &&
        policy &&
        clientId &&
        clientSecret &&
        scope &&
        grantType
      )
    },
    onSubmitExternalLinkAccount () {
      this.formSubmitted = true
      if (this.validateExternalLinkAccountForm()) {
        this.saveLoadingIcon = true
        const action = this.isEditPage
          ? 'settings/updateLinkAccount'
          : 'settings/addLinkAccount'
        if (!this.isEditPage) {
          this.externalLinkAccountDetails.tenantId = Number(
            this.selectedTenant[0].tenantID
          )
        }
        const postObj = {
          data: this.externalLinkAccountDetails,
          authenticationType: 'external-link-account'
        }
        this.$store
          .dispatch(action, postObj)
          .then((response) => {
            this.saveLoadingIcon = false
            if (response.data && Object.hasOwn(response.data, 'errorMessage')) {
              this.showToaster(response.data.errorMessage, false, 'danger')
            } else if (response.data) {
              this.showToaster(
                false,
                this.isEditPage
                  ? 'UPDATE_EXTERNAL_LINK_ACCOUNT'
                  : 'ADD_EXTERNAL_LINK_ACCOUNT',
                'success'
              )
              this.$router
                .push('/settings/settings-categories/link-accounts')
                .catch(() => {})
            } else {
              this.showToaster(false, 'FAILED', 'danger')
            }
          })
          .catch(() => {
            this.saveLoadingIcon = false
            this.showToaster(false, 'FAILED', 'danger')
          })
      }
    },
    setPropertyDependent () {
      if (this.selectedTenant.length && !this.isEditPage) {
        this.externalLinkAccountDetails.isForEachProperty = !!this
          .selectedTenant[0].isAddressSetupDone
      }
    }
  },
  watch: {
    selectedTenant (newValue, oldValue) {
      this.setPropertyDependent()
    }
  }
}
</script>
